import React from "react";
import API from "../helper/API";
// import ReactLoading from "react-loading";
import emailIcon from "../icons/email.svg"
import lockIcon from "../icons/lock.svg"
import profileIcon from "../icons/profile.svg"
import reconLogo from "../imgs/recon-logo-text.png"
import { motion } from "framer-motion"
import { FileUploader } from "react-drag-drop-files";
import { Oval } from 'react-loading-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const containerVariants = {
    hidden: {
        opacity: 0,
        y: "5vh"
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            duration: 0.2,
            stiffness: 100,
            delay: 0.1
        }
    }
}

const fileTypes = ["PDF", "PNG", "GIF", "JPEG", "TIFF", "PSD", "EPS", "AI"];

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            email: "",
            password: "",
            bio: "",
            imageBase64: "",
            error: "",
            loading: false,
        };

        this.Register = this.Register.bind(this);
        this.UpdateUsername = this.UpdateUsername.bind(this);
        this.UpdateEmail = this.UpdateEmail.bind(this);
        this.UpdateBio = this.UpdateBio.bind(this);
        this.UpdatePassword = this.UpdatePassword.bind(this);
        this.UpdateBase64 = this.UpdateBase64.bind(this)
        this.ConvertToBase64 = this.ConvertToBase64.bind(this)
    }

    UpdateBase64(base64) {
        this.setState({ imageBase64: base64 })
    }

    ConvertToBase64(file) {
        //Check File is not Empty
        if (file) {
            // FileReader function for read the file.

            const fileReader = new FileReader();
            var self = this;
            // Onload of file read the file content
            fileReader.onload = async function (fileLoadedEvent) {
                let base64 = fileLoadedEvent.target.result;
                self.UpdateBase64(base64);
            };
            // // Convert data to base64
            fileReader.readAsDataURL(file);
        }
    }

    UpdateUsername(event) {
        this.setState({
            username: event.target.value,
        });
    }

    UpdateEmail(event) {
        this.setState({
            email: event.target.value,
        });
    }

    UpdateBio(event) {
        this.setState({
            bio: event.target.value,
        });
    }

    UpdatePassword(event) {
        this.setState({
            password: event.target.value,
        });
    }

    async Register() {
        if (this.state.email === "" || this.state.username === "" || this.state.imageBase64 === "" || this.state.bio === "" || this.state.password === "") {
            return this.setState({ error: "please fill up all the forms" })
        }

        const data = {
            UserEmail: this.state.email,
            UserName: this.state.username,
            UserImage: this.state.imageBase64,
            UserBio: this.state.bio,
            UserPassword: this.state.password,
        };

        this.setState({ loading: true });

        await API.post("/register", data)
            .then((respone) => {
                const res = respone.data;

                if (res.ErrorMessage) {
                    toast.error(res.ErrorMessage, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                }

                if (res.data) {
                    // window.localStorage.setItem("token", res.data);
                    window.location = "/";
                } else {
                    this.setState({ error: "Error registering user" });
                }
            })
            .catch(function (error) {
                toast.error(error.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
            });
    }

    render() {
        return (
            <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="Login"
            >
                <form action="index.html" method="post">
                    <div className="logoContainer">
                        <img src={reconLogo} alt="" />
                    </div>
                    <p className="ErrorMessage">{this.state.error}</p>
                    <div>
                        <div className="inputLoginContainer">
                            <img src={profileIcon} alt="" />
                            <input
                                id="userName"
                                name="userName"
                                placeholder="Username"
                                type="text"
                                onChange={this.UpdateUsername}
                            />
                        </div>
                        <div className="inputLoginContainer">
                            <img src={emailIcon} alt="" />
                            <input
                                id="email"
                                name="email"
                                placeholder="Email"
                                type="text"
                                onChange={this.UpdateEmail}
                            />
                        </div>
                        <div className="inputLoginContainer">
                            <img src={lockIcon} alt="" />
                            <input
                                id="password"
                                name="password"
                                placeholder="Password"
                                type="password"
                                onChange={this.UpdatePassword}
                            />
                        </div>
                        <div className="inputLoginContainer">
                            <img src={lockIcon} id="textarea" alt="" />
                            <textarea
                                id="bio"
                                name="bio"
                                placeholder="Bio"
                                onChange={this.UpdateBio}
                            />
                        </div>
                        <div className="dragAndDrop">
                            <p>Profile Image</p>
                            <FileUploader handleChange={this.ConvertToBase64} name="file" types={fileTypes} />
                        </div>
                        <br />
                        <div className="LoginButton" onClick={this.Register}>
                            {this.state.loading ?
                                (
                                    <Oval />
                                )
                                :
                                (
                                    <span>Sign Up</span>
                                )
                            }

                        </div>
                        <br />
                        <div className="LinkContainer">
                            <p>Already have an account? <a href="/login">Sign In</a></p>
                        </div>
                        <div className="LinkContainer" >
                            <p>Do you need help? <a href="/contact" style={{ textDecoration: "underline" }}>Contact us here</a></p>
                        </div>
                    </div>
                </form>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </motion.div>
        );
    }
}

export default Login
