import React from "react";
import Moment from "moment";
import API from "../helper/API";
import Dropdown from "react-bootstrap/Dropdown";
import targetDefault from "../imgs/target.jpg"
import serverDefault from "../imgs/server.png"
import organizationDefault from "../imgs/organization.png"
import websiteDefault from "../imgs/website.png"
import noLastAccessBackground from "../imgs/no-last-access-background.svg"
import { BiDotsVerticalRounded } from "react-icons/bi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Navigate } from "react-router-dom";

class TargetCardDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            NotesCount: 0,
            image: "",
            redirect: false
        };

        this.GetNotesCount = this.GetNotesCount.bind(this);
        this.GetImage = this.GetImage.bind(this);
    }

    async GetImage() {
        const data = { TargetID: this.props.id };
        await API.post("/get_target_image", data).then(async (respone) => {
            const res = respone.data;
            if (res.ErrorMessage) {
                toast.error(res.ErrorMessage, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
            };
            if (
                res.data === false ||
                res.data.t_image === "" ||
                res.data.t_image === null
            ) {
                if (this.props.type === "website") {
                    console.log("testing")
                    this.setState({
                        image:
                            websiteDefault
                    });
                } else if (this.props.type === "organization") {
                    this.setState({
                        image:
                            organizationDefault
                    });
                } else if (this.props.type === "server") {
                    this.setState({
                        image:
                            serverDefault
                    });
                } else {
                    this.setState({
                        image:
                            targetDefault
                    });
                }
            } else {
                this.setState({ image: res.data.t_image });
            }
        }).catch(function (error) {
            toast.error(error.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        });
    }

    async GetNotesCount() {
        const data = { TargetID: this.props.id };
        await API.post("/get_target_notes_count", data).then(async (respone) => {
            const res = respone.data;

            if (res.ErrorMessage) {
                toast.error(res.ErrorMessage, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
            }

            if (res.data) {
                this.setState({ NotesCount: res.data[0].NotesCount });
            }
        }).catch(function (error) {
            toast.error(error.message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        });
    }

    NavigateToTarget() {
        this.setState({ redirect: true })
    }

    componentDidMount() {
        this.GetNotesCount();
        setTimeout(() => {
            if (!this.props.noLastAccess) {
                console.log("no last access")
                this.GetImage();
            }
        }, 800)
    }

    render() {
        return (
            <div
                className="TargetCardContainer"
                style={{ width: `${this.props.width}%` }}
            >
                <div className="TargetCardDashboard-container">
                    {
                        this.props.noLastAccess
                            ?
                            (
                                <img src={noLastAccessBackground} alt="vector" style={{ objectFit: "contain", backgroundColor: "rgb(230, 230, 230)" }} />
                            )
                            :
                            (
                                <img src={this.state.image} alt="vector" />
                            )
                    }

                    {
                        !this.props.noLastAccess
                        &&
                        (
                            <div className="TargetDescription">
                                <p className="TargetDescription-title">
                                    {this.props.name}
                                </p>
                                <p className="TargetDescription-date">
                                    {Moment(this.props.CreateDate).format("MMM Do YY")}
                                </p>
                                <p className="TargetDescription-description">
                                    <p style={{ fontWeight: "500" }}>{this.props.relationTarget && "Relation description :"}</p>
                                    {this.props.description.length < 77
                                        ? this.props.description
                                        : this.props.description.substring(0, 76) + "..."}
                                </p>
                            </div>
                        )
                    }
                    {
                        this.props.relationTarget && !this.props.noDotted
                        &&
                        (
                            <div className="TaskDottedIcon">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" className="DropDownToggle">
                                        <BiDotsVerticalRounded />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={this.props.DeleteRelationModal}>
                                            Delete Relation
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        )
                    }
                    {
                        this.props.status === "inactive" ?
                            (
                                <p style={{ paddingRight: "20px", color: "red" }}>

                                    {this.props.status}
                                </p>
                            )
                            :
                            (
                                <p style={{ paddingRight: "20px", color: "green" }}>
                                    {this.props.status}
                                </p>
                            )
                    }
                </div>

                <hr className="DashboardHr" style={{ display: this.props.noLastAccess ? "none" : null }} />
                <div className="TargetCardDashboardContainerStatus">
                    <div className="TargetCardDashboardContainerStatus-container">
                        <div>
                            Notes Count :
                            <p>{this.state.NotesCount}</p>
                        </div>
                        <span className="separator"></span>
                        <div>
                            {this.props.relationTarget ? "Relation type :" : "Type :"}
                            <p>{this.props.noLastAccess ? "Unknown" : this.props.type}</p>
                        </div>
                        <span className="separator"></span>
                        <div>
                            Last Update :
                            <p>{Moment(this.props.UpdateDate).format("MMM Do YY")}</p>
                        </div>
                    </div>
                </div>
                {
                    !this.props.noButton && !this.props.noLastAccess
                    &&
                    (
                        <div className="TargetCardDashboardContainerButton">
                            <button
                                className="TargetCardDashboardContainerButton-button"
                                onClick={() => {
                                    this.NavigateToTarget();
                                }}
                            >
                                Navigate to Target
                            </button>
                        </div>
                    )
                }
                {
                    this.state.redirect && <Navigate to={`/target_profile/${this.props.id}`} replace={true} />
                }
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        );
    }
}

export default TargetCardDashboard
