import React from "react";
import Comment from "./Comment";
import API from "../helper/API";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import deleteIcon from "../icons/delete.svg"
import sendIcon from "../icons/send.svg"
import { AiOutlineLink } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      CommentModal: false,
      DeleteModal: false,
      NewComment: {
        comment: "",
      },
    };

    this.GetComments = this.GetComments.bind(this);
    this.UploadComment = this.UploadComment.bind(this);
    this.UpdateComment = this.UpdateComment.bind(this);
    this.DeleteModal = this.DeleteModal.bind(this);
    this.DeletePost = this.DeletePost.bind(this);
  }

  async DeletePost() {
    const data = {
      PostID: this.props.id,
    };

    await API.post("/remove_post", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        };
        if (res.data) {
          this.props.GetPosts();
          this.props.GetPostsCount();
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  UpdateComment(event) {
    this.setState((prevState) => ({
      NewComment: {
        ...prevState.NewComment,
        comment: event.target.value,
      },
    }));
  }

  DeleteModal() {
    this.setState({ DeleteModal: !this.state.DeleteModal });
  }

  async GetComments() {
    const data = {
      PostID: this.props.id,
      Token: window.localStorage.getItem("token")
    };

    await API.post("/get_comments", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }
        if (res.data) this.setState({ comments: res.data });
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  async UploadComment() {
    if (this.state.NewComment.comment === "") {
      return;
    }
    const data = {
      CommnetPost: this.props.id,
      CommnetText: this.state.NewComment.comment,
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/add_comment", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }
        if (res.data) this.GetComments();
        this.setState({
          NewComment: {
            comment: ""
          }
        })
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  componentDidMount() {
    this.GetComments();
  }

  render() {
    return (
      <>
        <div className="Post" style={{ minHeight: this.props.fileContent ? "320px" : null }}>
          <div className="PostAuthContainer">
            <img alt="" className="PostAuthImage" src={this.props.UserImage} />
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
              <p className="Postauthor">{this.props.author}</p>
              <p className="Postauthor">{this.props.createDate.split("T")[0]}</p>
            </div>
            {this.props.BelongToUser ? (
              <div className="PostDeleteIcon">
                <img src={deleteIcon} onClick={this.DeleteModal} alt="" />
              </div>
            ) : null}
          </div>

          <p className="PostTitle">{this.props.title}</p>
          <p className="PostContent" style={{ fontWeight: 500 }}>
            {this.props.text}
          </p>
          {
            this.props.fileContent && (<a href={this.props.fileContent} download={this.props.fileName} className="PostFile"><AiOutlineLink />{this.props.fileName}</a>)
          }

          <div className="PostInputContainer" style={{ marginTop: this.props.fileContent ? "20px" : null }}>
            <input type="text" className="PostInput" onChange={this.UpdateComment} placeholder="reply" value={this.state.NewComment.comment} />
            <img src={sendIcon} className="PostInputIcon" onClick={this.UploadComment} alt="" />
          </div>
          <div
            style={{
              borderLeft: "2px solid rgb(187 187 187)",
              paddingLeft: "10px",
              marginTop: "20px"
            }}
          >
            {this.state.comments.map((comment, i) => {
              return (
                <Comment
                  key={i}
                  id={comment.c_id}
                  text={comment.c_text}
                  user={comment.u_name}
                  UserImage={comment.u_image}
                  BelongToUser={comment.BelongToUser}
                  createDate={comment.c_create_date}
                  refresh={this.GetComments}
                />
              );
            })}
          </div>
        </div>

        <Modal show={this.state.CommentModal} onHide={this.CommentModal}>
          <Modal.Header closeButton>
            <Modal.Title>New Comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={this.UpdateComment}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.CommentModal}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.UploadComment();
                this.CommentModal();
              }}
            >
              Add Comment
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.DeleteModal} onHide={this.DeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Post</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <p>Are you sure you want to delete this post</p>
            </Form>
            <div className="ModalButtons">
              <button
                className="DeleteButton"
                onClick={() => {
                  this.DeletePost();
                  this.DeleteModal();
                }}
              >Delete</button>
              <button className="CancelButton" onClick={this.DeleteModal}>Cancel</button>
            </div>
          </Modal.Body>
        </Modal>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }
}

export default Post
