import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import API from "../helper/API";
import { AiOutlineLink } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ToolCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DisplayTool: false,
            DeleteModal: false,
        };

        this.DisplayTool = this.DisplayTool.bind(this);
        this.DeleteTool = this.DeleteTool.bind(this);
        this.DeleteModal = this.DeleteModal.bind(this);
    }

    DeleteModal() {
        this.setState({ DeleteModal: !this.state.DeleteModal });
    }

    async DeleteTool() {
        const data = {
            WorkshopFileID: this.props.id,
        };

        await API.post("/remove_workshop_file", data)
            .then((respone) => {
                const res = respone.data;
                if (res.ErrorMessage) {
                    toast.error(res.ErrorMessage, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                };
                if (res.data) {
                    this.props.GetWorkshopFiles()
                }
            })
            .catch(function (error) {
                toast.error(error.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
            });
    }

    DisplayTool() {
        this.setState({ DisplayTool: !this.state.DisplayTool });
    }

    render() {
        return (
            <div className="ToolCardContainer">
                <p className="ToolContent">
                    <h4>{this.props.name}</h4>
                    <p style={{ fontWeight: "500" }}>
                        {this.props.description.length > 90
                            ? this.props.description.substring(0, 130) + " ..."
                            : this.props.description}
                    </p>
                    {
                        this.props.fileContent && (<a href={this.props.fileContent} download={this.props.fileName} className="ToolFile"><AiOutlineLink />{this.props.fileName}</a>)
                    }
                </p>

                <button className="BtnTool" onClick={this.DisplayTool}>
                    Display
                </button>

                <Modal show={this.state.DisplayTool} onHide={this.DisplayTool}>
                    <Modal.Body>
                        <div className="ToolModalContent">
                            <h4>{this.props.name}</h4>
                            <p className="ToolModalContent-Description">{this.props.description}</p>
                            {
                                this.props.fileContent && (<a href={this.props.fileContent} className="ToolModalContent-File" download={this.props.fileName}><AiOutlineLink />{this.props.fileName}</a>)
                            }
                        </div>

                        <div className="ModalButtons">
                            <button
                                className="OkButton"
                                onClick={this.DeleteModal}
                            >Delete</button>
                            <button className="CancelButton" onClick={this.DisplayTool}>Close</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.DeleteModal} onHide={this.DeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Tool</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <p>Are you sure you want to delete this Tool</p>
                        </Form>
                        <div className="ModalButtons">
                            <button
                                className="DeleteButton"
                                onClick={() => {
                                    this.DeleteTool();
                                    this.DeleteModal();
                                    this.DisplayTool();
                                }}
                            >Delete</button>
                            <button className="CancelButton" onClick={this.DeleteModal}>Cancel</button>
                        </div>
                    </Modal.Body>
                </Modal>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        )
    }
}

export default ToolCard