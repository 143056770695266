import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// style
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/custom.css";
import "./style/App.css"

// components
import NavBar from "./components/NavBar";
import PrivateRoute from "./Router/PrivateRoute";
import ContentWrapper from "./components/ContentWrapper";

// screens
import Dashboard from "./pages/Dashboard";
import TargetProfile from "./pages/TargetProfile";
import OperationProfile from "./pages/OperationProfile";
import Operations from "./pages/Operations";
import Targets from "./pages/Targets";
import Login from "./pages/Login";
import Register from "./pages/Register"
import PrivateNavBar from "./Router/PrivateNavBar";
import Profile from "./pages/Profile";
import About from "./pages/About";
import Contact from "./pages/Contact";
// import Documentation from "./pages/Documentation";
import SnackbarProvider from 'react-simple-snackbar'
import { AnimatePresence } from 'framer-motion'


function App() {
  return (
    <AnimatePresence exitBeforeEnter>
      <div className="App">
        <SnackbarProvider>
          <PrivateNavBar>
            <NavBar />
          </PrivateNavBar>

          <Routes location={window.location} key={window.location.pathname}>
            <Route exact path="/" element={<Navigate to="/dashboard" />} />
            <Route
              exact
              path="/dashboard"
              element={
                <PrivateRoute>
                  <ContentWrapper>
                    <Dashboard />
                  </ContentWrapper>
                </PrivateRoute>
              }
            />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route
              exact
              path="/profile"
              element={
                <PrivateRoute>
                  <ContentWrapper>
                    <Profile />
                  </ContentWrapper>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/about"
              element={
                <PrivateRoute>
                  <ContentWrapper>
                    <About />
                  </ContentWrapper>
                </PrivateRoute>
              }
            />
            {/* <Route
            exact
            path="/documentation"
            element={
              <PrivateRoute>
                <ContentWrapper>
                  <Documentation />
                </ContentWrapper>
              </PrivateRoute>
            }
          /> */}
            <Route exact path="/contact" element={<Contact />}
            />
            <Route
              exact
              path="/target_profile/:id"
              element={
                <PrivateRoute>
                  <ContentWrapper>
                    <TargetProfile />
                  </ContentWrapper>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/operations"
              element={
                <PrivateRoute>
                  <ContentWrapper>
                    <Operations />
                  </ContentWrapper>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/targets"
              element={
                <PrivateRoute>
                  <ContentWrapper>
                    <Targets />
                  </ContentWrapper>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/operation_profile/:id"
              element={
                <PrivateRoute>
                  <ContentWrapper>
                    <OperationProfile />
                  </ContentWrapper>
                </PrivateRoute>
              }
            />
          </Routes>
        </SnackbarProvider>
      </div>
    </AnimatePresence >
  );
}

export default App;
