import React from "react";
import Moment from "moment";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import API from "../helper/API";
import { AiOutlineLink } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class NoteCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DisplayNote: false,
      DeleteModal: false,
    };

    this.DisplayNote = this.DisplayNote.bind(this);
    this.DeleteNote = this.DeleteNote.bind(this);
    this.DeleteModal = this.DeleteModal.bind(this);
  }

  DeleteModal() {
    this.setState({ DeleteModal: !this.state.DeleteModal });
  }

  async DeleteNote() {
    const data = {
      NoteID: this.props.id,
    };

    await API.post("/remove_note", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }
        if (res.data) {
          this.props.GetNotes();
          this.props.GetNotesCount();
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  DisplayNote() {
    this.setState({ DisplayNote: !this.state.DisplayNote });
  }

  render() {
    return (
      <div className="NoteCardContainer">
        <p className="NoteTitle">
          <img className="PostAuthImage" src={this.props.UserImage} alt="" />
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <p>{this.props.author}</p>
            <p>{this.props.CreateDate.split("T")[0]}</p>
          </div>
        </p>
        <p className="NoteContent">
          <p>{this.props.title}</p>
          <p style={{ fontWeight: "500" }}>
            {this.props.text.length > 90
              ? this.props.text.substring(0, 130) + " ..."
              : this.props.text}
          </p>
          {
            this.props.fileContent && (<a href={this.props.fileContent} download={this.props.fileName} className="NoteFile"><AiOutlineLink />{this.props.fileName}</a>)
          }
        </p>

        <button className="BtnNote" onClick={this.DisplayNote}>
          Display
        </button>

        <Modal show={this.state.DisplayNote} onHide={this.DisplayNote}>
          <Modal.Body>
            <div className="NoteModalContent">
              <img src={this.props.UserImage} className="NoteModalContent-Img" alt="" />
              <div className="NoteModalContent-AuthData">
                <p className="NoteModalContent-AuthName">{this.props.author}</p>
                <p className="NoteModalContent-CreateDate">{Moment(this.props.CreateDate).format("MMM Do YY")}</p>
                <p className="NoteModalContent-Description">{this.props.type}</p>
              </div>
              <hr style={{ width: "100%", color: "rgba(43, 42, 42, 0.514)" }} />
              <p className="NoteModalContent-Title">{this.props.title}</p>
              <p className="NoteModalContent-Description">{this.props.text}</p>
              {
                this.props.fileContent && (<a href={this.props.fileContent} className="NoteModalContent-File" download={this.props.fileName}><AiOutlineLink />{this.props.fileName}</a>)
              }
            </div>

            <div className="ModalButtons">
              <button
                className="OkButton"
                onClick={this.DeleteModal}
              >Delete</button>
              <button className="CancelButton" onClick={this.DisplayNote}>Close</button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.DeleteModal} onHide={this.DeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <p>Are you sure you want to delete this Note</p>
            </Form>
            <div className="ModalButtons">
              <button
                className="DeleteButton"
                onClick={() => {
                  this.DeleteNote();
                  this.DeleteModal();
                  this.DisplayNote();
                }}
              >Delete</button>
              <button className="CancelButton" onClick={this.DeleteModal}>Cancel</button>
            </div>
          </Modal.Body>
        </Modal>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    );
  }
}

export default NoteCard
