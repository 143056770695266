import axios from "axios";

const url = "http://localhost:3001/";
// const url = "https://azhjtysl67.execute-api.us-east-2.amazonaws.com/production";

const Axios = axios.create({
  baseURL: url,
});

export default Axios;
