import React, { Component } from 'react'
import API from "../helper/API";
import profileIcon from "../icons/profile.svg"
import emailIcon from "../icons/email.svg"
import sendIcon from "../icons/send.svg"
import Form from "react-bootstrap/Form";
import { motion } from "framer-motion"
import { Navigate } from 'react-router-dom';
import { Oval } from 'react-loading-icons'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const containerVariants = {
    hidden: {
        opacity: 0,
        y: "5vh"
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            duration: 0.2,
            stiffness: 100,
            delay: 0.1
        }
    }
}

class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            category: "feedback",
            name: "",
            email: "",
            message: "",
            loading: false,
            goHome: false
        }
        this.UpdateName = this.UpdateName.bind(this)
        this.UpdateEmail = this.UpdateEmail.bind(this)
        this.UpdateMessage = this.UpdateMessage.bind(this)
        this.UpdateCategory = this.UpdateCategory.bind(this)
        this.SubmitContact = this.SubmitContact.bind(this)
    }

    UpdateName(e) {
        this.setState({ name: e.target.value })
    }

    UpdateEmail(e) {
        this.setState({ email: e.target.value })
    }

    UpdateMessage(e) {
        this.setState({ message: e.target.value })
    }

    UpdateCategory(e) {
        this.setState({ category: e.target.value })
    }

    async SubmitContact() {
        this.setState({ loading: true });
        const data = {
            ContactFormUsername: this.state.name,
            ContactFormEmail: this.state.email,
            ContactFormCategory: this.state.category,
            ContactFormMessage: this.state.message
        };

        await API.post("/add_message", data)
            .then((respone) => {
                const res = respone.data;

                if (res.ErrorMessage) {
                    toast.error(res.ErrorMessage, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                }

                if (res.data) {
                    toast.success("successfuly sent the message, we will into your message as soon as possible", {
                        position: "bottom-left",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                }

                setTimeout(() => {
                    this.setState({
                        goHome: true,
                        loading: false
                    })
                }, 3000)
            })
            .catch(function (error) {
                toast.error(error.message, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
            });
    }

    render() {
        return (
            <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className='Contact'
            >
                <form action="index.html" method="post">
                    <div className="logoContainer">
                        <h1><strong>Contact us</strong></h1>
                    </div>
                    <Form.Group>
                        <Form.Label>Purpose</Form.Label>
                        <Form.Select
                            onChange={this.UpdateCategory}
                        >
                            <option value="feedback">feedback</option>
                            <option value="fix">bug</option>
                            <option value="feature">feature</option>
                        </Form.Select>
                    </Form.Group>
                    <div className="inputContactContainer">
                        <img src={profileIcon} alt="" />
                        <input
                            id="user-name"
                            name="user-name"
                            placeholder="Name"
                            type="text"
                            onChange={this.UpdateName}
                        />
                    </div>
                    <div className="inputContactContainer">
                        <img src={emailIcon} alt="" />
                        <input
                            id="email"
                            name="email"
                            placeholder="Email"
                            type="email"
                            onChange={this.UpdateEmail}
                        />
                    </div>
                    <div className="inputContactContainer">
                        <img src={sendIcon} alt="" style={{ bottom: "69%" }} />
                        <textarea
                            id="message"
                            name="message"
                            placeholder="Message"
                            rows={3}
                            onChange={this.UpdateMessage}
                        />
                    </div>
                    <div className="ContactButton" onClick={this.SubmitContact}>
                        {
                            this.state.loading
                                ?
                                (
                                    <Oval />
                                )
                                :
                                (
                                    <span>Submit</span>
                                )
                        }
                    </div>
                </form>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                {
                    this.state.goHome
                    &&
                    (
                        <Navigate to={"/"} />
                    )
                }
            </motion.div>
        )
    }
}

export default Contact