import React from "react";
import API from "../helper/API";
import emailIcon from "../icons/email.svg"
import lockIcon from "../icons/lock.svg"
import { motion } from "framer-motion"
import reconLogo from "../imgs/recon-logo-text.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Oval } from 'react-loading-icons'

const containerVariants = {
  hidden: {
    opacity: 0,
    y: "5vh"
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: 0.2,
      stiffness: 100,
      delay: 0.1
    }
  }
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
      loading: false
    };

    this.Login = this.Login.bind(this);
    this.UpdateEmail = this.UpdateEmail.bind(this);
    this.UpdatePassword = this.UpdatePassword.bind(this);
  }

  UpdateEmail(event) {
    this.setState({
      email: event.target.value,
      error: ""
    });
  }

  UpdatePassword(event) {
    this.setState({
      password: event.target.value,
      error: ""
    });
  }

  async Login() {
    this.setState({ loading: true });
    const data = {
      Email: this.state.email,
      Password: this.state.password,
    };

    await API.post("/login", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }

        if (res.data) {
          window.localStorage.setItem("token", res.data);
          window.location = "/";
        } else {
          this.setState({ error: "Invalid Email or Password", loading: false });
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  componentDidMount() {
    if (window.localStorage.getItem("token")) {
      window.location = "/";
    }
  }

  render() {
    return (
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="Login"
      >
        <form action="index.html" method="post">
          <div className="logoContainer">
            <img src={reconLogo} alt="" />
          </div>
          <p className="ErrorMessage">{this.state.error}</p>
          <div>
            <div className="inputLoginContainer">
              <img src={emailIcon} alt="" />
              <input
                id="user-name"
                name="user-name"
                placeholder="Email"
                type="text"
                onChange={this.UpdateEmail}
              />
            </div>
            <div className="inputLoginContainer">
              <img src={lockIcon} alt="" />
              <input
                id="password"
                name="password"
                placeholder="Password"
                type="password"
                onChange={this.UpdatePassword}
              />
            </div>
            <br />
            <div className="LoginButton" onClick={this.Login}>
              {
                this.state.loading
                  ?
                  (
                    <Oval />
                  )
                  :
                  (
                    <span>Sign In</span>
                  )
              }

            </div>
            <br />
            <div className="LinkContainer">
              <p>Don't have an account? <a href="/register">Sign Up</a></p>
            </div>
            <div className="LinkContainer" >
              <p>Do you need help? <a href="/contact" style={{ textDecoration: "underline" }}>Contact us here</a></p>
            </div>
          </div>
        </form>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </motion.div>
    );
  }
}

export default Login
