import React from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { FileUploader } from "react-drag-drop-files";
import { useParams } from "react-router-dom";
import { FiSearch } from "react-icons/fi"
import filterIcon from "../icons/Filter.svg"
import { motion } from "framer-motion"
import emptyBoxIcon from "../icons/empty-box.svg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import NoteCard from "../components/NoteCard";
import API from "../helper/API";
import Pagination from "../components/Pagination";
import TargetCardDashboard from "../components/TargetCardDashboard"
import ProfilesNavigationBar from "../components/ProfilesNavigationBar";

const fileTypes = ["PDF", "PNG", "GIF", "JPEG", "TIFF", "PSD", "EPS", "AI"];
const fileTypesPost = ["ASP", "ASPX", "CFM", "CGI", "PL", "CSS", "HTML", "JS", "JSP", "PY", "PHP", "XHTML", "C", "CLASS", "CPP", "CS", "H", "JAVA", "SWIFT", "DOC", "DOCX", "ODT", "PDF", "RTF", "TEX", "TXT", "WPD", "SH", "PNG", "GIF", "JPEG", "TIFF", "PSD", "EPS", "AI"];

const withParams = (Component) => (props) => {
  const { id } = useParams();
  return <Component {...props} id={id} />;
};

const containerVariants = {
  hidden: {
    opacity: 0,
    y: "5vh"
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: 0.2,
      stiffness: 100,
      delay: 0.1
    }
  }
}

class TargetProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      location: "",
      NotesCount: 0,
      type: "",
      NotesFilter: "",
      RelationsFilter: "",
      RelatedByFilter: "",
      SearchNotes: "",
      SearchRelations: "",
      SearchRelatedByTargets: "",
      description: "",
      CreateDate: "",
      UpdateDate: "",
      State: "",
      notes: [],
      relations: [],
      RelatedBY: [],
      targets: [],
      operation: "",
      NotesTab: 1,
      RelationsTab: 0,
      RelatedByTab: 0,
      SettingsTab: 0,
      NotesModal: false,
      RelationsModal: false,
      DeleteRelationModal: false,
      SettingsModal: false,
      UploadNewImageModal: false,
      currentPageNotes: 1,
      notesToDisplayNumber: 4,
      currentPageRelations: 1,
      relationsToDisplayNumber: 4,
      currentPageRelatedBy: 1,
      relatedByToDisplayNumber: 4,
      relationToDeleteID: "",
      NewImageBase64: "",
      NewNote: {
        title: "",
        text: "",
        type: "",
        base64: "",
        fileName: ""
      },
      NewRelation: {
        RelatedTargetName: "Related Target",
        RelatedTarget: "",
        type: "",
        description: "",
      },
      NewInfo: {
        name: "",
        description: "",
        location: "",
      },
    };

    this.targetCardRef = React.createRef();

    this.SwitchSlider = this.SwitchSlider.bind(this);
    this.NotesModal = this.NotesModal.bind(this);
    this.RelationsModal = this.RelationsModal.bind(this);
    this.GetTargetInfo = this.GetTargetInfo.bind(this);
    this.GetNotes = this.GetNotes.bind(this);
    this.UpdateNoteTitle = this.UpdateNoteTitle.bind(this);
    this.UpdateNoteText = this.UpdateNoteText.bind(this);
    this.UpdateNoteType = this.UpdateNoteType.bind(this);
    this.UpdateRelationRelatedTarget = this.UpdateRelationRelatedTarget.bind(this);
    this.UpdateRelationType = this.UpdateRelationType.bind(this);
    this.UpdateRelationDescription = this.UpdateRelationDescription.bind(this);
    this.UploadNote = this.UploadNote.bind(this);
    this.UploadRelation = this.UploadRelation.bind(this);
    this.UploadNewImageModal = this.UploadNewImageModal.bind(this);
    this.GetRelations = this.GetRelations.bind(this);
    this.GetRelatedByTargets = this.GetRelatedByTargets.bind(this);
    this.GetTargets = this.GetTargets.bind(this);
    this.UpdateRelationRelatedTargetName = this.UpdateRelationRelatedTargetName.bind(this);
    this.SettingsModal = this.SettingsModal.bind(this);
    this.GetNotesCount = this.GetNotesCount.bind(this);
    this.UpdateTargetImage = this.UpdateTargetImage.bind(this)
    this.UpdateSearchNotes = this.UpdateSearchNotes.bind(this);
    this.UpdateSearchRelations = this.UpdateSearchRelations.bind(this);
    this.UpdateSearchRelatedByTargets = this.UpdateSearchRelatedByTargets.bind(this);
    this.UpdateFilterNotes = this.UpdateFilterNotes.bind(this);
    this.UpdateFilterRelations = this.UpdateFilterRelations.bind(this);
    this.UpdateFilterRelatedByTargets = this.UpdateFilterRelatedByTargets.bind(this);
    this.InfoModal = this.InfoModal.bind(this);

    this.UploadNewInfo = this.UploadNewInfo.bind(this);
    this.UpdateNewInfoName = this.UpdateNewInfoName.bind(this);
    this.UpdateNewInfoDescription = this.UpdateNewInfoDescription.bind(this);
    this.UpdateNewInfoLocation = this.UpdateNewInfoLocation.bind(this);
    this.UpdateTargetState = this.UpdateTargetState.bind(this);

    this.DeleteTarget = this.DeleteTarget.bind(this);
    this.DeleteModal = this.DeleteModal.bind(this);
    this.DeleteRelationModal = this.DeleteRelationModal.bind(this);
    this.DeleteRelation = this.DeleteRelation.bind(this);


    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.SetLastAccessedTarget = this.SetLastAccessedTarget.bind(this);

    this.UpdateBase64 = this.UpdateBase64.bind(this);
    this.UpdateBase64NewNote = this.UpdateBase64NewNote.bind(this)
    this.convertToBase64 = this.convertToBase64.bind(this);
  }

  UpdateBase64NewNote(base64, fileName) {
    this.setState((prevState) => ({
      NewNote: {
        ...prevState.NewNote,
        base64: base64,
        fileName: fileName
      },
    }));
  }

  UpdateBase64(base64) {
    this.setState({ NewImageBase64: base64 })
  }

  convertToBase64(file) {

    //Check File is not Empty
    if (file) {

      // FileReader function for read the file.
      const fileReader = new FileReader();
      var self = this;

      // Onload of file read the file content
      fileReader.onload = async function (fileLoadedEvent) {
        let base64 = fileLoadedEvent.target.result;
        let fileName = file.name;
        if (self.state.NotesModal === true) {
          self.UpdateBase64NewNote(base64, fileName)
        } else {
          self.UpdateBase64(base64);
        }
      };
      // // Convert data to base64
      fileReader.readAsDataURL(file);
    }
  }

  async SetLastAccessedTarget() {
    const data = {
      TargetID: this.props.id,
    };

    await API.post("/set_last_accessed_target", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  InfoModal() {
    this.setState((prevState) => ({
      InfoModal: !this.state.InfoModal,
      NewInfo: {
        ...prevState.NewInfo,
        name: "",
        description: "",
        location: "",
      },
    }));
  }

  async UploadNewInfo() {
    if (
      ((this.state.NewInfo.location === "") && !this.state.location.length)
      ||
      ((this.state.NewInfo.description === "") && !this.state.description.length)
      ||
      ((this.state.NewInfo.name === "") && !this.state.name.length)
    ) {
      return;
    }

    let TargetName;
    let TargetDescription;
    let TargetLocation;

    if (this.state.NewInfo.name === "" && this.state.name.length) {
      TargetName = this.state.name
    } else {
      TargetName = this.state.NewInfo.name
    }

    if (this.state.NewInfo.description === "" && this.state.description.length) {
      TargetDescription = this.state.description
    } else {
      TargetDescription = this.state.NewInfo.description
    }

    if (this.state.NewInfo.location === "" && this.state.location.length) {
      TargetLocation = this.state.location
    } else {
      TargetLocation = this.state.NewInfo.location
    }

    const data = {
      TargetID: this.props.id,
      TargetName,
      TargetDescription,
      TargetLocation,
    };
    await API.post("/update_target_info", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }
        if (res.data) {
          this.GetTargetInfo();
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  async UpdateTargetImage() {
    if (this.state.NewImageBase64 === "") {
      return;
    }

    const data = {
      TargetID: this.state.id,
      TargetImage: this.state.NewImageBase64
    }

    await API.post("/update_target_image", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        } else {
          this.targetCardRef.current.GetImage()
        }

      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  UpdateNewInfoName(event) {
    this.setState((prevState) => ({
      NewInfo: {
        ...prevState.NewInfo,
        name: event.target.value,
      },
    }));
  }

  UpdateNewInfoDescription(event) {
    this.setState((prevState) => ({
      NewInfo: {
        ...prevState.NewInfo,
        description: event.target.value,
      },
    }));
  }

  UpdateNewInfoLocation(event) {
    this.setState((prevState) => ({
      NewInfo: {
        ...prevState.NewInfo,
        location: event.target.value,
      },
    }));
  }

  async UpdateTargetState(event) {
    let TargetState;
    if (event.target.checked === false) {
      this.setState({ State: "inactive" })
      TargetState = "inactive"
    } else {
      this.setState({ State: "active" })
      TargetState = "active"
    }

    const data = {
      TargetID: this.props.id,
      TargetState: TargetState,
    };

    await API.post("/update_target_state", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }

        if (res.data) {
          this.GetTargetInfo();
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  async DeleteTarget() {
    const data = {
      TargetID: this.props.id,
    };

    await API.post("/remove_target", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }
        if (res.data) {
          window.location = "/targets";
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  DeleteModal() {
    this.setState({ DeleteModal: !this.state.DeleteModal });
  }

  async UpdateFilterNotes(value) {
    if (value === "all") {
      await this.GetNotes();
    }

    if (value === "title") {
      await this.GetNotes();
      await this.setState({ NotesFilter: value });
      let filteredArray = this.state.notes.sort((a, b) => {
        return a.n_title.localeCompare(b.n_title);
      });
      await this.setState({ notes: filteredArray });
    }

    if (value === "date") {
      await this.GetNotes();
      await this.setState({ NotesFilter: value });
      let filteredArray = this.state.notes.sort((a, b) => {
        var c = new Date(a.n_create_date);
        var d = new Date(b.n_create_date);
        return c - d;
      });
      await this.setState({ notes: filteredArray });
    }

    if (value === "info type") {
      await this.GetNotes();
      await this.setState({ NotesFilter: value });
      let filteredArray = this.state.notes.filter((note, i) => {
        return note.n_type === "info"
      });
      await this.setState({ notes: filteredArray });
    }

    if (value === "vulnerability type") {
      await this.GetNotes();
      await this.setState({ NotesFilter: value });
      let filteredArray = this.state.notes.filter((note, i) => {
        return note.n_type === "vulnerability"
      });
      await this.setState({ notes: filteredArray });
    }

    if (value === "gain access type") {
      await this.GetNotes();
      await this.setState({ NotesFilter: value });
      let filteredArray = this.state.notes.filter((note, i) => {
        return note.n_type === "gain access"
      });
      await this.setState({ notes: filteredArray });
    }
  }

  async UpdateFilterRelations(value) {
    if (value === "all") {
      await this.GetRelations();
    }

    if (value === "date") {
      await this.GetRelations();
      await this.setState({ RelationsFilter: value });
      let filteredArray = this.state.relations.sort((a, b) => {
        var c = new Date(a.t_create_date);
        var d = new Date(b.t_create_date);
        return c - d;
      });
      await this.setState({ relations: filteredArray });
    }

    if (value === "name") {
      await this.GetRelations();
      await this.setState({ RelationsFilter: value });
      let filteredArray = this.state.relations.sort((a, b) => {
        return a.t_name.localeCompare(b.t_name);
      });
      await this.setState({ relations: filteredArray });
    }
  }

  async UpdateFilterRelatedByTargets(value) {
    if (value === "all") {
      await this.GetRelatedByTargets();
    }

    if (value === "date") {
      await this.GetRelatedByTargets();
      await this.setState({ RelatedByFilter: value });
      let filteredArray = this.state.RelatedBY.sort((a, b) => {
        var c = new Date(a.t_create_date);
        var d = new Date(b.t_create_date);
        return c - d;
      });
      await this.setState({ RelatedBY: filteredArray });
    }

    if (value === "name") {
      await this.GetRelatedByTargets();
      await this.setState({ RelatedByFilter: value });
      let filteredArray = this.state.RelatedBY.sort((a, b) => {
        return a.t_name.localeCompare(b.t_name);
      });
      await this.setState({ RelatedBY: filteredArray });
    }
  }

  async UpdateSearchRelatedByTargets(event) {
    await this.setState({ SearchRelatedByTargets: event.target.value });
    this.GetRelatedByTargets();
  }

  async UpdateSearchNotes(event) {
    await this.setState({ SearchNotes: event.target.value });
    this.GetNotes();
  }

  async UpdateSearchRelations(event) {
    await this.setState({ SearchRelations: event.target.value });
    this.GetRelations();
  }

  SettingsModal() {
    this.setState({ SettingsModal: !this.state.SettingsModal });
  }

  async GetRelatedByTargets() {
    const data = {
      TargetID: this.props.id,
      SearchRelatedByTargets: this.state.SearchRelatedByTargets,
    };

    await API.post("/get_related_by_targets", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }
        if (res.data) {
          this.setState({
            RelatedBY: res.data,
          });
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  async GetTargets() {
    const id = window.localStorage.getItem("token");
    const data = { Token: id, OperationID: this.state.operation };

    await API.post("/get_targets", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }
        if (res.data) {
          this.setState({ targets: res.data });
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  UpdateRelationRelatedTarget(event) {
    const nameAndId = event.target.value.split(" ")
    this.setState((prevState) => ({
      NewRelation: {
        ...prevState.NewRelation,
        RelatedTarget: nameAndId[0],
      },
    }));
    this.UpdateRelationRelatedTargetName(nameAndId[1])
  }

  UpdateRelationRelatedTargetName(value) {
    this.setState((prevState) => ({
      NewRelation: {
        ...prevState.NewRelation,
        RelatedTargetName: value,
      },
    }));
  }

  UpdateRelationType(event) {
    this.setState((prevState) => ({
      NewRelation: {
        ...prevState.NewRelation,
        type: event.target.value,
      },
    }));
  }

  UpdateRelationDescription(event) {
    this.setState((prevState) => ({
      NewRelation: {
        ...prevState.NewRelation,
        description: event.target.value,
      },
    }));
  }

  async UploadRelation() {
    const data = {
      RelationType: this.state.NewRelation.type,
      Token: window.localStorage.getItem("token"),
      RelationRelatedTarget: !this.state.NewRelation.RelatedTarget.length ? this.state.targets[0].t_id : this.state.NewRelation.RelatedTarget,
      RelationDescription: this.state.NewRelation.description,
      RelationTarget: this.props.id,
    };

    await API.post("/add_relation", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }
        if (res.data) {
          this.GetRelations();
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  async DeleteRelation() {
    const data = {
      r_id: this.state.relationToDeleteID
    }

    await API.post("/remove_relation", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }

        if (res.data) {
          this.GetRelations();
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  DeleteRelationModal(id) {
    this.setState({
      relationToDeleteID: id,
      DeleteRelationModal: !this.state.DeleteRelationModal
    })
  }

  UpdateNoteTitle(event) {
    this.setState((prevState) => ({
      NewNote: {
        ...prevState.NewNote,
        title: event.target.value,
      },
    }));
  }

  UpdateNoteText(event) {
    this.setState((prevState) => ({
      NewNote: {
        ...prevState.NewNote,
        text: event.target.value,
      },
    }));
  }

  UpdateNoteType(event) {
    this.setState((prevState) => ({
      NewNote: {
        ...prevState.NewNote,
        type: event.target.value,
      },
    }));
  }

  async UploadNote() {
    const data = {
      Token: window.localStorage.getItem("token"),
      NoteOperation: this.state.operation,
      NoteTarget: this.props.id,
      NoteType: !this.state.NewNote.type.length ? "vulnerability" : this.state.NewNote.type,
      NoteTitle: this.state.NewNote.title,
      NoteText: this.state.NewNote.text,
      NoteFileContent: this.state.NewNote.base64 ? this.state.NewNote.base64 : "",
      NoteFileName: this.state.NewNote.fileName ? this.state.NewNote.fileName : ""
    };

    await API.post("/add_note", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }
        if (res.data) {
          this.GetNotes();
          this.GetNotesCount();
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  async GetNotes() {
    const data = {
      TargetID: this.props.id,
      SearchNotes: this.state.SearchNotes,
    };

    await API.post("/get_notes", data)
      .then(async (respone) => {
        const res = respone.data;
        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }

        for (let i = 0; i < res.data.length; i++) {
          const data = {
            NoteID: res.data[i].n_id,
          };
          await API.post("/get_note_files", data)
            .then((respone) => {
              const response = respone.data;
              // if (response.ErrorMessage) window.alert(response.ErrorMessage);
              res.data[i].nf_name = response.data.nf_name
              res.data[i].nf_content = response.data.nf_content
            })
            .catch(function (error) {
              // toast.error(error.message, {
              //   position: "bottom-left",
              //   autoClose: 5000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              //   theme: "colored",
              // })
            });
        }

        if (res.data) {
          this.setState({
            notes: res.data,
          });
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  async GetRelations() {
    const data = {
      TargetID: this.props.id,
      SearchRelations: this.state.SearchRelations,
    };

    await API.post("/get_relations", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }
        if (res.data) {
          this.setState({
            relations: res.data,
          });
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  async GetTargetInfo() {
    const data = {
      TargetID: this.props.id,
    };

    await API.post("/get_target_info", data)
      .then((respone) => {
        const res = respone.data;
        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }
        if (res.data) {
          this.setState({
            id: res.data[0].t_id,
            name: res.data[0].t_name,
            NotesCount: 5,
            type: res.data[0].t_type,
            description: res.data[0].t_description,
            operation: res.data[0].t_operation,
            CreateDate: res.data[0].t_create_date,
            location: res.data[0].t_location,
            UpdateDate: res.data[0].t_update_date,
            State: res.data[0].t_state
          });
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  NotesModal() {
    this.setState((prevState) => ({
      NotesModal: !this.state.NotesModal,
      NewNote: {
        ...prevState.NewNote,
        title: "",
        text: "",
        type: "",
        base64: ""
      },
    }));
  }

  RelationsModal() {
    this.setState({
      RelationsModal: !this.state.RelationsModal,
    });
  }

  SwitchSlider(slide) {
    switch (slide) {
      case "Notes":
        this.setState({
          NotesTab: 1,
          RelationsTab: 0,
          RelatedByTab: 0,
          SettingsTab: 0
        });
        break;

      case "Relations":
        this.setState({
          NotesTab: 0,
          RelationsTab: 1,
          RelatedByTab: 0,
          SettingsTab: 0
        });
        break;

      case "RelatedBy":
        this.setState({
          NotesTab: 0,
          RelationsTab: 0,
          RelatedByTab: 1,
          SettingsTab: 0
        });
        break;

      case "Settings":
        this.setState({
          NotesTab: 0,
          RelationsTab: 0,
          RelatedByTab: 0,
          SettingsTab: 1
        });
        break;

      default:
        break;
    }
  }

  async GetNotesCount() {
    const data = { TargetID: this.props.id };
    await API.post("/get_target_notes_count", data).then(async (respone) => {
      const res = respone.data;
      if (res.ErrorMessage) {
        toast.error(res.ErrorMessage, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      }
      if (res.data) {
        this.setState({ NotesCount: res.data[0].NotesCount });
      }
    }).catch((error) => {
      toast.error(error.message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    });
  }

  UploadNewImageModal() {
    this.setState({ UploadNewImageModal: !this.state.UploadNewImageModal })
  }

  setCurrentPage(page, type) {
    if (type === "notes") {
      this.setState({ currentPageNotes: page });
    } else if (type === "relations") {
      this.setState({ currentPageRelations: page });
    } else if (type === "relatedBy") {
      this.setState({ currentPageRelatedBy: page });
    }
  }

  async componentDidMount() {
    await this.GetTargetInfo();
    this.GetTargets();
    this.GetNotes();
    this.GetNotesCount();
    this.GetRelations();
    this.GetRelatedByTargets();
    this.SetLastAccessedTarget();
  }

  render() {
    let lastNotesIndex =
      this.state.currentPageNotes * this.state.notesToDisplayNumber;
    let firstNotesIndex = lastNotesIndex - this.state.notesToDisplayNumber;
    const currentNotesToDisplay = this.state.notes.slice(
      firstNotesIndex,
      lastNotesIndex
    );

    let lastRelationsIndex =
      this.state.currentPageRelations * this.state.relationsToDisplayNumber;
    let firstRelationsIndex =
      lastRelationsIndex - this.state.relationsToDisplayNumber;
    const currentRelationsToDisplay = this.state.relations.slice(
      firstRelationsIndex,
      lastRelationsIndex
    );

    let lastRelatedByIndex =
      this.state.currentPageRelatedBy * this.state.relatedByToDisplayNumber;
    let firstRelatedByIndex =
      lastRelatedByIndex - this.state.relatedByToDisplayNumber;
    const currentRelatedByToDisplay = this.state.RelatedBY.slice(
      firstRelatedByIndex,
      lastRelatedByIndex
    );
    return (
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="TargetProfile"
      >
        <div className="TargetProfileContent">
          <div className="TargetProfileCardContainer">
            {
              this.state.id
              &&
              <TargetCardDashboard
                id={this.state.id}
                ref={this.targetCardRef}
                name={this.state.name}
                description={this.state.description}
                type={this.state.type}
                CreateDate={this.state.CreateDate}
                UpdateDate={this.state.UpdateDate}
                status={this.state.State}
                noButton
                width={100}
              />
            }
          </div>

          <div className="TargetProfileSlider">
            <div
              className="NotesSlide"
              style={{ display: this.state.NotesTab ? null : "none" }}
            >
              <div className="SearchContainer" style={{ margin: "0px", marginTop: "40px" }}>
                <div className="SearchInputContainer">
                  <input
                    placeholder="Search by name or description"
                    type="text"
                    className="Search"
                    onChange={this.UpdateSearchNotes}
                  />
                  <FiSearch size={25} />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
                  <Dropdown style={{ width: "auto" }}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{ border: "none", backgroundColor: "transparent" }}
                    >
                      <img src={filterIcon} width={30} alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item key="all" onClick={() => this.UpdateFilterNotes("all")}>
                        <span style={{ marginRight: "25px", color: "black" }}>
                          All
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item key="title" onClick={() => this.UpdateFilterNotes("title")}>
                        <span style={{ marginRight: "50px" }}>By Title</span>
                      </Dropdown.Item>
                      <Dropdown.Item key="date" onClick={() => this.UpdateFilterNotes("date")}>
                        <span style={{ marginRight: "50px" }}>By Date</span>
                      </Dropdown.Item>
                      <Dropdown.Item key="info type" onClick={() => this.UpdateFilterNotes("info type")}>
                        <span style={{ marginRight: "50px" }}>Type: info</span>
                      </Dropdown.Item>
                      <Dropdown.Item key="vulnerability type" onClick={() => this.UpdateFilterNotes("vulnerability type")}>
                        <span style={{ marginRight: "50px" }}>Type: vulnerability</span>
                      </Dropdown.Item>
                      <Dropdown.Item key="gain access type" onClick={() => this.UpdateFilterNotes("gain access type")}>
                        <span style={{ marginRight: "50px" }}>Type: gain access</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <ProfilesNavigationBar
                type="targetProfile"
                SwitchSlider={this.SwitchSlider}
                notesActive
              />
              <button className="NewNoteButton" onClick={this.NotesModal}>add new note</button>
              {
                !this.state.notes.length
                &&
                (
                  <div className="NoDataHeader-Container">
                    <h1 className="NoDataHeader-Content">There are no notes in this target try adding one!</h1>
                    <img src={emptyBoxIcon} alt="" />
                  </div>
                )
              }
              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                {currentNotesToDisplay.map((note, i) => {
                  return (
                    <NoteCard
                      key={i}
                      id={note.n_id}
                      title={note.n_title}
                      text={note.n_text}
                      type={note.n_type}
                      author={note.u_name}
                      fileName={note.nf_name}
                      fileContent={note.nf_content}
                      CreateDate={note.n_create_date}
                      UpdateDate={note.n_update_date}
                      UserImage={note.u_image}
                      GetNotes={this.GetNotes}
                      GetNotesCount={this.GetNotesCount}
                    />
                  );
                })}
              </div>
              <Pagination
                type="notes"
                totalOperationsNumber={this.state.notes.length}
                postsToDisplayNumber={this.state.notesToDisplayNumber}
                setCurrentPage={this.setCurrentPage}
                currentPage={this.state.currentPageNotes}
              />
            </div>

            <div
              className="RelationSlide"
              style={{ display: this.state.RelationsTab ? null : "none" }}
            >
              <div className="SearchContainer" style={{ margin: "0px", marginTop: "40px" }}>
                <div className="SearchInputContainer">
                  <input
                    placeholder="Search by name or description"
                    type="text"
                    className="Search"
                    onChange={this.UpdateSearchRelations}
                  />
                  <FiSearch size={25} />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
                  <Dropdown style={{ width: "auto" }}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{ border: "none", backgroundColor: "transparent" }}
                    >
                      <img src={filterIcon} width={30} alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item key="all" onClick={() => this.UpdateFilterRelations("all")}>
                        <span style={{ marginRight: "25px", color: "black" }}>
                          All
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item key="name" onClick={() => this.UpdateFilterRelations("name")}>
                        <span style={{ marginRight: "50px" }}>By Name</span>
                      </Dropdown.Item>
                      <Dropdown.Item key="date" onClick={() => this.UpdateFilterRelations("date")}>
                        <span style={{ marginRight: "50px" }}>By Date</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <ProfilesNavigationBar
                type="targetProfile"
                SwitchSlider={this.SwitchSlider}
                relationsActive
              />
              <button className="NewRelationButton" onClick={this.RelationsModal}>add new relation</button>
              {
                !this.state.relations.length
                &&
                (
                  <div className="NoDataHeader-Container">
                    <h1 className="NoDataHeader-Content">This target doesn't have a relation with any of the other targets!</h1>
                    <img src={emptyBoxIcon} alt="" />
                  </div>
                )
              }
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {currentRelationsToDisplay.map((relation, i) => {
                  return (
                    <TargetCardDashboard
                      key={i}
                      id={relation.t_id}
                      name={relation.t_name}
                      description={relation.r_description}
                      type={relation.r_type}
                      relation={relation.r_type}
                      CreateDate={relation.t_create_date}
                      UpdateDate={relation.t_update_date}
                      DeleteRelationModal={() => this.DeleteRelationModal(relation.r_id)}
                      width={100}
                      relationTarget
                    />
                  );
                })}
              </div>
              <Pagination
                type="relations"
                totalOperationsNumber={this.state.relations.length}
                postsToDisplayNumber={this.state.relationsToDisplayNumber}
                setCurrentPage={this.setCurrentPage}
                currentPage={this.state.currentPageRelations}
              />
            </div>

            <div
              className="RelatedBySlide"
              style={{ display: this.state.RelatedByTab ? null : "none" }}
            >
              <div className="SearchContainer" style={{ margin: "0px", marginTop: "40px" }}>
                <div className="SearchInputContainer">
                  <input
                    placeholder="Search by name or description"
                    type="text"
                    className="Search"
                    onChange={this.UpdateSearchRelatedByTargets}
                  />
                  <FiSearch size={25} />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
                  <Dropdown style={{ width: "auto" }}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{ border: "none", backgroundColor: "transparent" }}
                    >
                      <img src={filterIcon} width={30} alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item key="all" onClick={() => this.UpdateFilterRelatedByTargets("all")}>
                        <span style={{ marginRight: "25px", color: "black" }}>
                          All
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item key="name" onClick={() => this.UpdateFilterRelatedByTargets("name")}>
                        <span style={{ marginRight: "50px" }}>By Name</span>
                      </Dropdown.Item>
                      <Dropdown.Item key="date" onClick={() => this.UpdateFilterRelatedByTargets("date")}>
                        <span style={{ marginRight: "50px" }}>By Date</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <ProfilesNavigationBar
                type="targetProfile"
                SwitchSlider={this.SwitchSlider}
                relatedByActive
              />
              {
                !this.state.RelatedBY.length
                &&
                (
                  <div className="NoDataHeader-Container">
                    <h1 className="NoDataHeader-Content">This target is not related with any of the other targets!</h1>
                    <img src={emptyBoxIcon} alt="" />
                  </div>
                )
              }
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {currentRelatedByToDisplay.map((relation, i) => {
                  return (
                    <TargetCardDashboard
                      key={i}
                      id={relation.t_id}
                      name={relation.t_name}
                      description={relation.r_description}
                      type={relation.r_type}
                      relation={relation.r_type}
                      CreateDate={relation.t_create_date}
                      UpdateDate={relation.t_update_date}
                      width={100}
                      relationTarget
                      noDotted
                    />
                  );
                })}
              </div>
              <Pagination
                type="relatedBy"
                totalOperationsNumber={this.state.RelatedBY.length}
                postsToDisplayNumber={this.state.relatedByToDisplayNumber}
                setCurrentPage={this.setCurrentPage}
                currentPage={this.state.currentPageRelatedBy}
              />
            </div>

            <div
              className="SettingsSlide"
              style={{ display: this.state.SettingsTab ? null : "none" }}
            >
              <div className="SearchContainer"></div>
              <ProfilesNavigationBar
                type="targetProfile"
                SwitchSlider={this.SwitchSlider}
                settingsActive
              />
              <div className="SettingsCard">
                <div className="SettingsCard-Content">
                  <div className="UpdateInfo">
                    <p className="UpdateInfo-Header">Update</p>
                    <p className="UpdateInfo-Body">You can update this target by clicking Update next to this text</p>
                    <button className="UpdateInfoButton" onClick={this.InfoModal}>update information</button>
                  </div>
                  <div className="SwitchState">
                    <div>
                      <p className="SwitchState-Header">Switch state</p>
                      <p className="SwitchState-Body">You can switch the activity of this target by clicking
                        Switch state next to this text</p>
                    </div>
                    <Form>
                      <Form.Check
                        type="switch"
                        id="SwitchState-SwitchButton"
                        checked={this.state.State === "active" ? true : false}
                        onChange={this.UpdateTargetState}
                      />
                    </Form>
                  </div>
                  <div className="UpdateImage">
                    <p className="UpdateImage-Header">Upload new image</p>
                    <p className="UpdateImage-Body">You can upload a new image by clicking Upload next to this text</p>
                    <button className="UpdateImageButton" onClick={this.UploadNewImageModal}>upload a new image</button>
                  </div>
                  <div className="DeleteOperation">
                    <p className="DeleteOperation-Header">Delete</p>
                    <p className="DeleteOperation-Body"> You can delete this target by clicking Delete next to this text</p>
                    <button className="DeleteOperationButton" onClick={this.DeleteModal}>delete target</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={this.state.NotesModal} onHide={this.NotesModal}>
            <Modal.Header closeButton>
              <Modal.Title>New Note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Note Title here..."
                    autoFocus
                    onChange={this.UpdateNoteTitle}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Type</Form.Label>
                  <Form.Select
                    onChange={this.UpdateNoteType}
                  >
                    <option key={"vulnerability"} value={"vulnerability"} >
                      vulnerability
                    </option>
                    <option key={"info"} value={"info"} >
                      info
                    </option>
                    <option key={"gain access"} value={"gain access"} >
                      gain access
                    </option>
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Content</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter Note Content here..."
                    rows={3}
                    onChange={this.UpdateNoteText}
                  />
                </Form.Group>
                <div className="dragAndDrop">
                  <p>Upload files (Up to one file)</p>
                  <FileUploader handleChange={this.convertToBase64} name="file" types={fileTypesPost} />
                </div>
              </Form>
              <div className="ModalButtons">
                <button
                  className="OkButton"
                  onClick={() => {
                    this.UploadNote();
                    this.NotesModal();
                  }}
                >Save Note</button>
                <button className="CancelButton" onClick={this.NotesModal}>Cancel</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={this.state.RelationsModal} onHide={this.RelationsModal}>
            <Modal.Header closeButton>
              <Modal.Title>New Relation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Relation Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Relation Type here..."
                    autoFocus
                    onChange={this.UpdateRelationType}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Related Target</Form.Label>
                  <Form.Select
                    onChange={this.UpdateRelationRelatedTarget}
                  >
                    {this.state.targets
                      .filter((target) => {
                        return target.t_id !== this.state.id
                      })
                      .map((target, i) => {
                        return (
                          <option
                            key={i}
                            value={target.t_id}
                          >
                            {target.t_name}
                          </option>
                        )
                      })}
                  </Form.Select>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label style={{ marginTop: "1rem" }}>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter Relation Description here..."
                    rows={3}
                    onChange={this.UpdateRelationDescription}
                  />
                </Form.Group>
              </Form>
              <div className="ModalButtons">
                <button
                  className="OkButton"
                  onClick={() => {
                    this.UploadRelation();
                    this.RelationsModal();
                  }}
                >Save Relation</button>
                <button className="CancelButton" onClick={this.RelationsModal}>Cancel</button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={this.state.InfoModal} onHide={this.InfoModal}>
            <Modal.Header closeButton>
              <Modal.Title>New Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Target Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Target Name here..."
                    defaultValue={this.state.name}
                    autoFocus
                    onChange={this.UpdateNewInfoName}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Target Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter Target Description here..."
                    defaultValue={this.state.description}
                    rows={3}
                    autoFocus
                    onChange={this.UpdateNewInfoDescription}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Target Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Target Location here..."
                    defaultValue={this.state.location}
                    autoFocus
                    onChange={this.UpdateNewInfoLocation}
                  />
                </Form.Group>
              </Form>
              <div className="ModalButtons">
                <button
                  className="OkButton"
                  onClick={() => {
                    this.UploadNewInfo();
                    this.InfoModal();
                  }}
                >Save</button>
                <button className="CancelButton" onClick={this.InfoModal}>Cancel</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={this.state.UploadNewImageModal} onHide={this.UploadNewImageModal}>
            <Modal.Header closeButton>
              <Modal.Title>Upload new image </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="dragAndDrop">
                <FileUploader handleChange={this.convertToBase64} name="file" types={fileTypes} />
              </div>
              <div className="ModalButtons">
                <button
                  className="OkButton"
                  onClick={() => {
                    this.UploadNewImageModal();
                    this.UpdateTargetImage();
                  }}
                >Save</button>
                <button className="CancelButton" onClick={this.UploadNewImageModal}>Cancel</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={this.state.DeleteModal} onHide={this.DeleteModal}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Operation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <p>Are you sure you want to delete this Target?</p>
              </Form>
              <div className="ModalButtons">
                <button
                  className="DeleteButton"
                  onClick={() => {
                    this.DeleteTarget();
                    this.DeleteModal();
                  }}
                >Delete</button>
                <button className="CancelButton" onClick={this.DeleteModal}>Cancel</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={this.state.DeleteRelationModal} onHide={this.DeleteRelationModal}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Relation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <p>Are you sure you want to delete this Relation?</p>
              </Form>
              <div className="ModalButtons">
                <button
                  className="DeleteButton"
                  onClick={() => {
                    this.DeleteRelationModal();
                    this.DeleteRelation();
                  }}
                >Delete</button>
                <button className="CancelButton" onClick={this.DeleteRelationModal}>Cancel</button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </motion.div>
    );
  }
}

export default withParams(TargetProfile);
