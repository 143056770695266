import React from "react";
import reconLogo from "../imgs/recon-logo.png"
import dashboardIcon from "../icons/dashboard-fill.svg"
import operationIcon from "../icons/operation.svg"
import targetIcon from "../icons/target.svg"
import logoutIcon from "../icons/Logout.svg"
import profileIcon from "../icons/profile.svg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import aboutIcon from "../icons/about.svg"
// import documentationIcon from "../icons/documentation.svg";
import {
  CDBSidebar,
  CDBSidebarFooter,
  CDBSidebarHeader,
} from "cdbreact";
import { NavLink } from "react-router-dom";
import { Oval } from 'react-loading-icons';
import { motion } from "framer-motion";

import API from "../helper/API";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLinksActive: false,
      isOpened: true,
      allowClose: false,
      wasClosed: false,
      UserImage: "",
      UserName: "",
      loading: false
    };
    this.sideBarRef = React.createRef()
    this.Logout = this.Logout.bind(this);
    this.GetUserInfo = this.GetUserInfo.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.closeDrawer = this.closeDrawer.bind(this)
  }

  async GetUserInfo() {
    const data = {
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/get_user_info", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }

        if (res.data) {
          this.setState({
            UserImage: res.data.u_image,
            UserName: res.data.u_name
          })
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  async Logout() {
    this.setState({ loading: true })
    const data = {
      Token: window.localStorage.getItem("token"),
    };

    await API.post("/logout", data)
      .then((respone) => {
        const res = respone.data;

        if (res.ErrorMessage) {
          toast.error(res.ErrorMessage, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }

        if (res.data) {
          window.localStorage.removeItem("token");
          window.location = "/login";
        }
      })
      .catch(function (error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      });
  }

  componentDidMount() {
    if (window.innerWidth <= 920 && !this.state.allowClose === true) {
      if (this.state.isOpened === true) {
        setTimeout(() => document.getElementById("menu-icon").click(), 100)

        this.setState({ allowClose: true, isOpened: false })
      } else {
        this.setState({ allowClose: true })
      }

    } else if (window.innerWidth > 920 && !this.state.allowClose === false) {
      if (this.state.isOpened === false) {
        this.setState({ allowClose: false, isOpened: true })
      } else {
        this.setState({ allowClose: false })
      }
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 920 && !this.state.allowClose === true) {
        if (!this.state.wasClosed) {
          this.setState({ allowClose: true, isOpened: true })
        } else {
          this.setState({ allowClose: true, isOpened: false })
        }
      } else if (window.innerWidth > 920 && !this.state.allowClose === false) {
        if (this.state.isOpened === false) {
          this.setState({ allowClose: false, isOpened: true, wasClosed: true })
        } else {
          this.setState({ allowClose: false, wasClosed: false })
        }
      }
    })
  }

  handleDrawerOpen() {
    if (this.sideBarRef.current.getBoundingClientRect().width === 270) {
      this.setState({ isOpened: false })
    } else {
      this.setState({ isOpened: true })
    }
  }

  closeDrawer() {
    if (this.sideBarRef.current.getBoundingClientRect().width === 270) {
      document.getElementById("menu-icon").click()
      this.setState({ isOpened: false })

    }
  }

  render() {
    return (
      <>
        <div
          className={this.state.allowClose ? "sideBar-container" : "sideBar-container sideBar-container-Open"}
        >
          <CDBSidebar textColor="#202020" backgroundColor="rgb(255, 255, 255)" ref={this.sideBarRef}>
            {
              this.state.allowClose === true
              &&
              (
                <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large" id="menu-icon" onClick={this.handleDrawerOpen} style={{ color: "rgb(10, 10, 10)" }}></i>}>
                </CDBSidebarHeader>
              )
            }
            <CDBSidebarFooter style={{ textAlign: "center" }} >
              <img className="sideBar-logo" src={reconLogo} alt=""
                style={{
                  objectFit: this.state.isOpened ? "contain" : "cover",
                  height: this.state.isOpened ? "100px" : "50px",
                  marginTop: this.state.allowClose ? "10px" : "70px"
                }}
              />
              <div className={this.state.isOpened ? "sideBar-footer" : "sideBar-footer sideBar-footer-closed"}>
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    isActive ? "sideBar-active" : undefined
                  }
                  onClick={this.state.allowClose && this.closeDrawer}
                >
                  <img src={dashboardIcon} style={{ width: "22px" }} alt="" />
                  <span>Dashboard</span>
                </NavLink>
                <NavLink
                  to="/operations"
                  className={({ isActive }) =>
                    isActive ? "sideBar-active" : undefined
                  }
                  onClick={this.state.allowClose && this.closeDrawer}
                >
                  <img src={operationIcon} style={{ width: "22px" }} alt="" />
                  <span>Operations</span>
                </NavLink>
                <NavLink
                  to="/targets"
                  className={({ isActive }) =>
                    isActive ? "sideBar-active" : undefined
                  }
                  onClick={this.state.allowClose && this.closeDrawer}
                >
                  <img src={targetIcon} style={{ width: "22px" }} alt="" />
                  <span>Targets</span>
                </NavLink>
                <NavLink
                  to="/profile"
                  className={({ isActive }) =>
                    isActive ? "sideBar-active" : undefined
                  }
                  onClick={this.state.allowClose && this.closeDrawer}
                >
                  <img src={profileIcon} style={{ width: "22px" }} alt="" />
                  <span>profile</span>
                </NavLink>
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    isActive ? "sideBar-active" : undefined
                  }
                  onClick={this.state.allowClose && this.closeDrawer}
                >
                  <img src={aboutIcon} style={{ width: "24px" }} alt="" />
                  <span>About</span>
                </NavLink>
                {/* <NavLink
                  to="/documentation"
                  className={({ isActive }) =>
                    isActive ? "sideBar-active" : undefined
                  }
                  onClick={this.state.allowClose && this.closeDrawer}
                >
                  <img src={documentationIcon} style={{ width: "24px" }} alt="" />
                  <span>Documentation</span>
                </NavLink> */}
                <NavLink onClick={this.Logout} >
                  <img src={logoutIcon} style={{ width: "22px" }} alt="" />
                  <span>logout</span>
                </NavLink>
              </div>

            </CDBSidebarFooter>
          </CDBSidebar>
        </div >
        {
          this.state.loading
          &&
          (
            <motion.div
              initial={{ y: -100 }}
              animate={{ y: 0 }}
              className="loadingContainer"
            >
              <Oval />
            </motion.div>
          )
        }
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }
}

export default NavBar
