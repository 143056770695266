import React, { Component } from 'react'
import { motion } from "framer-motion"

const containerVariants = {
    hidden: {
        opacity: 0,
        y: "5vh"
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            duration: 0.2,
            stiffness: 100,
            delay: 0.1
        }
    }
}

export default class About extends Component {
    render() {
        return (
            <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className='AboutPage-Container'
            >
                <h1 className='AboutPage-Header'>About ReconTrail</h1>
                <div className='AboutPage-block'>
                    <h3>How Does ReconTrail Improve Cyber Operations?</h3>
                    <p>
                        ReconTrail used to monitor teams during cyber operations is an essential tool for any organization involved in this type of activity. This software enables teams to create operations, add team members, set targets, and add scripts and tools to the operation workshop. It also provides real-time status updates on the progress of the operation, allowing teams to quickly adapt to changing circumstances.
                    </p>
                </div>


                <div className='AboutPage-block'>
                    <h3>Organizing Targets with ReconTrail</h3>
                    <p>
                        ReconTrail includes a variety of features that are designed to help teams stay organized and coordinated. For example, each target has its own profile, which can be used to add notes and categorize them. This allows teams to easily keep track of important information about each target, such as their vulnerabilities and potential points of attack. Additionally, relations between targets can be defined, providing a clear understanding of how different targets are interconnected.
                    </p>
                </div>
                <div className='AboutPage-block'>
                    <h3>Streamlining Team Communication with ReconTrail</h3>
                    <p>
                        One of the most important aspects of successful cyber operations is communication and coordination among team members. The software includes a built-in communication system, which allows team members to share posts and files with each other. This helps to ensure that everyone is on the same page and that important information is being shared in a timely manner. This feature is especially useful when dealing with large, complex operations, as it allows teams to stay in close contact even when they are working remotely.
                    </p>
                </div>
                <p className='AboutPage-Copyright'>all copy rights return to recontrail © 2020-2022</p>
            </motion.div>
        )
    }
}
