import React, { Component } from 'react'
import targetDefault from "../imgs/target.jpg"
import serverDefault from "../imgs/server.png"
import organizationDefault from "../imgs/organization.png"
import websiteDefault from "../imgs/website.png"

export default class RecentTargetCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            image: ""
        }
    }

    GoToTarget(id) {
        window.location.href = `/target_profile/${id}`;
    }

    componentDidMount() {
        if (this.props.image === "") {
            if (this.props.type === "website") {
                this.setState({
                    image:
                        websiteDefault
                });
            } else if (this.props.type === "organization") {
                this.setState({
                    image:
                        organizationDefault
                });
            } else if (this.props.type === "server") {
                this.setState({
                    image:
                        serverDefault
                });
            } else {
                this.setState({
                    image:
                        targetDefault
                });
            }
        } else {
            this.setState({
                image:
                    this.props.image
            })
        }
    }

    render() {
        return (
            <div className="targetCard" key={this.props.id}>
                <div className="targetCard-content">
                    <img
                        alt=""
                        src={this.state.image}
                    />
                    <div className="targetCard-text">
                        <h5>{this.props.name}</h5>
                        <p>{this.props.description}</p>
                    </div>
                </div>
                <div className="targetCard-buttonContainer">
                    <button
                        className="targetCard-button"
                        onClick={() => this.GoToTarget(this.props.id)}
                    >
                        Navigate to Target
                    </button>
                </div>
            </div>
        )
    }
}
